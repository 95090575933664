import './../styles/Services.css'
import React from 'react'

const About = () => (
  <div className="services">
    <h1>About Us</h1>
    <p>Information about the company...</p>
  </div>
)

export default About
