import React from 'react'
import './../styles/Services.css'

const Community = () => (
  <div className="services">
    <h1>Community</h1>
    <p>Information about the community...</p>
  </div>
)

export default Community
