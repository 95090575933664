import React, { useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'
import '../styles/EmblaCarousel.css'
// Import images
import image1 from '../assets/clientLogos/Puma-logo-black-Transparent-Background.png'
import image2 from '../assets/clientLogos/Alpha-transparent.png'
import image3 from '../assets/clientLogos/DAZN_transparent_logo.png'
import image4 from '../assets/clientLogos/gsg_logo_transparent.png'
import image5 from '../assets/clientLogos/newsuk_transparent_logo.png'
import image6 from '../assets/clientLogos/DVSA_transparent_logo.png'
import image7 from '../assets/clientLogos/vega_transparent_logo.webp'
import image8 from '../assets/clientLogos/ovo_transparent_logo.png'
import image9 from '../assets/clientLogos/TfGM_transparent_logo.png'
import image10 from '../assets/clientLogos/BBC_Sounds_transprent_logo.png'
import image11 from '../assets/clientLogos/virgin-hols_transparent_background.png'

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11]

const EmblaCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: 'start' }, [
    AutoScroll({ playOnInit: true, delay: 500, stopOnInteraction: false }),
  ])
  /* eslint-disable no-unused-vars */
  const [isPlaying, setIsPlaying] = useState(true)

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    setIsPlaying(autoScroll.isPlaying())
    emblaApi
      .on('autoScroll:play', () => setIsPlaying(true))
      .on('autoScroll:stop', () => setIsPlaying(false))
      .on('reInit', () => setIsPlaying(autoScroll.isPlaying()))
  }, [emblaApi])

  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {images.map((src, index) => (
            <div className="embla__slide" key={index}>
              <img className="embla__slide__img" src={src} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EmblaCarousel
