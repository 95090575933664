import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './../styles/Header.css'
import logo from '../assets/logo-small.png'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('nav') && isOpen) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="QA Tech Logo" className="logo" />
        </Link>
      </div>
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <ul className="nav-links" onClick={closeMenu}>
          <li>
            <Link to="/">Home</Link>
          </li>
          {/* <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/community">Community</Link>
          </li> */}
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
      </nav>
    </header>
  )
}

export default Header
