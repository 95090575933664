import React from 'react'
import './../styles/Footer.css'
import { Link } from 'react-router-dom'
import linkedinLogo from '../assets/social/linkedin-ligth-blue-icon.png' // Ensure the path is correct

const Footer = () => (
  <footer className="footer">
    <div className="footer-content">
      <div className="footer-left">
        <p>&copy; {new Date().getFullYear()} QA Tech Consultancy. All rights reserved.</p>
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </div>
      <div className="footer-right">
        <h4>Follow us</h4>
        <a href="https://www.linkedin.com/company/qa-tech-consultancy" target="_blank" rel="noopener noreferrer">
          <img src={linkedinLogo} alt="LinkedIn Logo" className="social-media-logo" />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
