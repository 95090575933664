import React from 'react'
import './../styles/OurServices.css'

const services = {
  'Software Testing Models': [
    'Managed Testing Services',
    'Software Testers for Hire',
    'QA Outsourcing',
    'QA Consulting',
    'Dedicated QA Team',
    'QA as a Service',
  ],
  'Software Testing Solutions': [
    'Test Automation',
    'Digital Transformation',
    'Performance Testing',
    'End-to-End Testing Services',
    'Load and Stress Testing',
    'API Testing',
    'Accessibility Testing',
    'Functional Testing',
    'Regression Testing',
    'Integration Testing',
    'Compatibility Testing',
    'Ad Hoc Testing',
    'User Acceptance Testing',
  ],
  'Languages & tools': [
    'Javascript',
    'Typescript',
    'Java',
    '.Net/C#',
    'Selenium',
    'Cucumber',
    'Cypress.io',
    'Playwright',
    'Webdriver',
    'Mocha',
  ],
  'Systems & Platforms': [
    'Web Apps',
    'Mobile Apps',
    'IoT',
    'SaaS',
    'SAP',
    'ERP',
    'Enterprises',
    'Cloud',
    'Blockchain',
    'CRM',
    'AWS',
    'Azure',
  ],
}

const OurServices = () => (
  <div className="services-container">
    <h2>Our Services</h2>
    <div className="services-list">
      {Object.entries(services).map(([category, items]) => (
        <div className="service-category" key={category}>
          <h3>{category}</h3>
          <ul>
            {items.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
)

export default OurServices
