import React from 'react'
import './../styles/Services.css'

const Services = () => (
  <div className="services">
    <h1>Our Services</h1>
    <p>We offer comprehensive testing and development services...</p>
  </div>
)

export default Services
