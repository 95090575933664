import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser'
import './../styles/ContactForm.css'

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const consent = watch('consent', false)

  const onSubmit = (data) => {
    setIsLoading(true)
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        data,
        process.env.REACT_APP_EMAILJS_PUB_KEY
      )
      .then(
        () => {
          setIsSubmitted(true)
          setErrorMessage('')
          setIsLoading(false)
          reset()
        },
        (error) => {
          setIsSubmitted(false)
          setErrorMessage('Failed to send the message. Please try again later.')
          setIsLoading(false)
          console.error('Failed...', error.text)
        }
      )
  }

  return (
    <div className="container" id="contact-form">
      <div className="message-container">
        <h1 className="heading">Ready to make change happen?</h1>
        <div className="contact-details">
          <p>
            Email: <a href="mailto:info@qatechltd.co.uk">info@qatechltd.co.uk</a>
          </p>
        </div>
      </div>
      <div className="form-container">
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="form-group half-width">
              <label htmlFor="firstName">
                First name<span className="asterisk">*</span>
              </label>
              <input id="firstName" {...register('firstName', { required: true })} style={{ maxWidth: '85%' }} />
              {errors.firstName && <span className="error-message">First name is required</span>}
            </div>
            <div className="form-group half-width">
              <label htmlFor="lastName">Last name</label>
              <input id="lastName" {...register('lastName')} style={{ maxWidth: '85%' }} />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">
              Email<span className="asterisk">*</span>
            </label>
            <input id="email" {...register('email', { required: true })} />
            {errors.email && <span className="error-message">Email is required</span>}
          </div>
          <div className="form-group">
            <label htmlFor="companyName">Company Name (optional)</label>
            <input id="companyName" {...register('companyName')} />
          </div>
          <div className="form-group">
            <label htmlFor="message">
              Your Message<span className="asterisk">*</span>
            </label>
            <textarea
              id="message"
              {...register('message', { required: true, maxLength: 500 })}
              maxLength="500"
            ></textarea>
            {errors.message && (
              <span className="error-message">Message is required and should be less than 500 characters</span>
            )}
          </div>
          <div className="form-group consent-group">
            <input type="checkbox" {...register('consent', { required: true })} />
            <label>
              I consent to my personal information being processed in accordance with the{' '}
              <Link to="/privacy-policy">privacy policy</Link>.
            </label>
          </div>
          {isSubmitted && <h2 className="success-message">Thank you! Your message has been sent successfully.</h2>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit" disabled={!consent || isLoading} className={!consent || isLoading ? 'disabled' : ''}>
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
